import { Box, Flex, Text, Image, VStack, SimpleGrid, Button } from "@chakra-ui/react"
import { FiChevronRight } from "react-icons/fi"
import { CosmicPressRelease } from "lib/cosmic/pressReleases"

const PressPreview = ({ metadata }): JSX.Element => {
  const description = metadata.description.slice(0, 100).trim()

  return (
    <Flex
      flexDirection={{ base: "column", md: "row" }}
      width="100%"
      cursor="pointer"
      justifyContent="flex-start"
      alignItems={{ base: "top", md: "none" }}
      onClick={() => window.open(metadata.external_url, "_blank")}
      flexGrow={1}
    >
      <Box
        width={"100%"}
        minWidth="312px"
        background="white"
        borderRadius="12px"
        height={["auto", "100%"]}
        py={0}
        px={0}
        border="1px solid #E3D3FF"
        overflow="hidden"
        display="flex"
        flexDirection="column"
      >
        <Image boxSize="50%" objectFit="cover" src={metadata.thumbnail.url} alt={metadata.headline} height="200px" minW="100%" borderTopLeftRadius="10px" borderTopRightRadius="10px" />
        <VStack align="left" padding="16px" flexGrow={1}>
          <Text color="black" fontSize="20px" fontWeight="bold" textAlign="left">
            {metadata.headline}
          </Text>
          <Text color="var(--website-color-p-on-light)" fontSize="18px" textAlign="left">
            {description.endsWith(".") ? description : `${description}...`}
          </Text>
        </VStack>
        <Button
          justifyContent="flex-start"
          variant="ghost"
          color="var(--website-color-highlight)"
          mt="4px"
          mb="8px"
          fontSize="16px"
          _hover={{
            opacity: "0.5",
          }}
        >
          Read More
          <FiChevronRight size="16px" />
        </Button>
      </Box>
    </Flex>
  )
}

export const NewsSection = ({ pressReleases }: { pressReleases: CosmicPressRelease[] }) => {
  return (
    <Flex margin="0 auto" flexDirection="column" alignItems="center" maxWidth="var(--website-width-max)" pb={[10, 10, 20]} px={[5, 0, 0]}>
      <Text fontSize={["var(--website-font-size-h1-mobile)", "var(--website-font-size-h1)"]} lineHeight="1.1" marginTop={["0px", "64px"]} mx={["64px", "0px"]} mb="12px" textAlign="center">
        Integral in the News
      </Text>

      <Text textAlign="center" color="var(--website-color-p-on-light)" width={["90%", "90%", "55%"]} fontSize="20px" mb="32px">
        With our software, users can quickly create de-identified datasets that are ready for use in days instead of weeks.
      </Text>

      <SimpleGrid columns={[1, 1, 2]} spacing={8} width={["90%", "100%"]} maxW="1200px">
        {pressReleases.slice(0, 8).map((pressRelease, i) => (
          <PressPreview key={i} metadata={pressRelease.metadata} />
        ))}
      </SimpleGrid>
    </Flex>
  )
}

export default NewsSection
